// src/indexedDB.js
import { openDB } from 'idb';

const DB_NAME = 'Vidyadip';
const STORE_NAME = 'VidyadipStore';
const VERSION = 1;

const initDB = async () => {
  const db = await openDB(DB_NAME, VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME);
        // store.createIndex('Selection', ['board_id', 'medium_id', 'standard_id']);
      }
    },
  });
  return db;
};

const clearAllRecords = async () => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  await store.clear();

  await tx.done;
};

const addData = async (data,key) => {
  const { board_id, medium_id, standard_id } = data;

  // Check for existing data with the same composite key
  if(key == 'setting') await clearAllRecords();

  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  data.board_id = Number(board_id);
  data.medium_id = Number(medium_id);
  data.standard_id = Number(standard_id);

  await store.put(data,key);
  await tx.done;
};

const getAllData = async () => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  const data = await store.getAll();
  await tx.done;
  return data;
};

const getData = async (id) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  const data = await store.get(id);
  await tx.done;
  return data;
};

const deleteData = async (id) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.delete(id);
  await tx.done;
};


export { addData, getData, getAllData, deleteData };
