import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_PATH, queryStringToJSON } from '../const';
import { FetchPostApi } from '../Apiservice';
import { Drawer } from 'vaul';
import myFunction from '../Helper';
import { Accordion } from 'react-bootstrap';

function Videos(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const [options, setoptions] = useState({
        "page": 1,
        "limit": 100,
    })
    const [paginateOption, setpaginateOption] = useState()
    const [videolist, setvideolist] = useState([])
    const [videodrawer, setvideodrawer] = useState(false)
    const [slectvideo, setslectvideo] = useState([])



    const hasnext = () => {
        setoptions({ ...options, page: options.page + 1 })
    }
    const hasprev = () => {
        setoptions({ ...options, page: options.page - 1 })
    }

    useEffect(() => {
        const data = queryStringToJSON(location.search);
        Fetchingvideo(data)
    }, [location]);

    async function Fetchingvideo(data) {
        const temp = { ...options, whereArray: data }
        const video = await FetchPostApi(API_PATH.Getvideo, temp)

        if (video.success) {
            setvideolist(video.data.data)
            setpaginateOption(video.data.paginateOption);
        } else {
            setvideolist([])
            setpaginateOption();
        }
    }

    const extractVideoId = (url) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };


    return (
        <div className='container-md'>
            <section className='row py-3 sticky-top z-1 top-0 bg-white shadow-sm'>
                <div className='col-12 d-flex align-items-center'>
                    <div className='back-arrow' onClick={() => navigate('/subject' + location.state.current, { state: { data: location.state.prev } })}>
                        <span className='cursor-pointer'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 8L1 8" stroke="#363D4E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 15L1 8L8 1" stroke="#363D4E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <div className="common-title mx-auto">
                        <h3 className="mb-0">Videos</h3>
                    </div>
                </div>
            </section>
            <section className='row scroll-quiz'>
                <div className='col-md-12'>
                    {videolist.length > 0 && (
                        <Accordion defaultActiveKey="0" flush className='mt-4 cust-acc'>

                            {videolist.map((item, i) => {
                                return (
                                    <>
                                        <Accordion.Item eventKey={i.toString()} className='mb-3'>
                                            <Accordion.Header>{item.channel_name}</Accordion.Header>
                                            <Accordion.Body>
                                            {item.video.length > 0 &&
                                                <ul className='cust-select-radio'>
                                                    {item.video.map((item2, i) => {
                                                        var link_code = extractVideoId(item2.video_url)
                                                        const thumbnailUrl = `https://img.youtube.com/vi/${link_code}/hqdefault.jpg`;
                                                        const videoUrl = `https://www.youtube.com/watch?v=${link_code}`;
                                                        return (
                                                            <li>
                                                                <Link to={videoUrl} target='blank'>
                                                                    <label className='d-flex align-items-center gap-3 p-2 video-sec'>
                                                                        <div className='thumbnail w-50'>
                                                                            <img
                                                                                src={thumbnailUrl}
                                                                                alt="Video placeholder"
                                                                                className='object-fit-contain img-fluid ratio ratio-16x9 rounded-10'
                                                                                loading='lazy'
                                                                            />
                                                                        </div>
                                                                        {item2.name}
                                                                    </label>
                                                                </Link>

                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                )
                            })}
                        </Accordion>
                    )
                    }
                </div>
            </section>
            {(paginateOption && options.page != paginateOption.lastPage) &&
                <section className='row py-3 sticky-bottom z-1 top-0 bg-white shadow-sm border-top pagination'>
                    <div className='col-12 d-flex align-items-center'>
                        {paginateOption.hasPrev &&
                            <div className='back-arrow' onClick={() => hasprev()}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_209_2937)">
                                        <rect x="2" y="1" width="36" height="36" rx="8" fill="white" />
                                        <rect x="2.5" y="1.5" width="35" height="35" rx="7.5" stroke="#D0D5DD" />
                                        <path d="M25.8334 19H14.1667M14.1667 19L20 24.8333M14.1667 19L20 13.1666" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_209_2937" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="1" />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_209_2937" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_209_2937" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                        }
                        <div className="common-title mx-auto">
                            <h3 className="mb-0">Page {options.page} of {paginateOption.lastPage}</h3>
                        </div>
                        {paginateOption.hasNext &&
                            <div className='back-arrow' onClick={() => hasnext()}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_209_2939)">
                                        <rect x="2" y="1" width="36" height="36" rx="8" fill="white" />
                                        <rect x="2.5" y="1.5" width="35" height="35" rx="7.5" stroke="#D0D5DD" />
                                        <path d="M14.1666 19H25.8333M25.8333 19L20 13.1666M25.8333 19L20 24.8333" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_209_2939" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="1" />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_209_2939" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_209_2939" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                        }
                    </div>
                </section>
            }



            {/* //answer drawer */}

            <Drawer.Root
                shouldScaleBackground
                open={videodrawer}
                // dismissible
                // modal
                onClose={() => setvideodrawer(false)}
            >
                <Drawer.Portal>
                    <div onClick={() => setvideodrawer(false)}>
                        <Drawer.Overlay className='cust-overlay-drawer' />
                    </div>
                    <Drawer.Content>
                        <div className='drawer-inner pt-2'>
                            <div className='row'>
                                <div className='col-md-12 d-flex justify-content-center'>
                                    <div className='border-top-notch'>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='d-flex'>
                                        <h3>{slectvideo.channel_name}</h3>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='row scrollable-drawer solutions-drw'>
                                        <div className='col-md-12'>
                                            {slectvideo.video && slectvideo.video.length > 0 &&
                                                <ul className='cust-select-radio'>
                                                    {slectvideo.video.map((item, i) => {
                                                        var link_code = extractVideoId(item.video_url)
                                                        const thumbnailUrl = `https://img.youtube.com/vi/${link_code}/hqdefault.jpg`;
                                                        const videoUrl = `https://www.youtube.com/watch?v=${link_code}`;
                                                        return (
                                                            <li>
                                                                <Link to={videoUrl} target='blank'>

                                                                    <label className='d-flex align-items-center gap-3'>
                                                                        <div className='thumbnail'>
                                                                            <img
                                                                                src={thumbnailUrl}
                                                                                alt="Video placeholder"
                                                                                className='img-fluid'
                                                                                width={100}
                                                                                height={100}
                                                                            />
                                                                        </div>
                                                                        {item.name}
                                                                    </label>
                                                                </Link>

                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>
        </div>
    );
}

export default Videos;