export const FetchPostApi = async (path, body) => {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(body)
    };
    const PostApiData = await fetch(path, options)
        .then((response) => {
            // return response;
            return response.json()

        })
        .catch((err) => {
            return err.response;
        });
    return PostApiData;
};

export const FetchGetApi = async (path, body) => {
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(body)
    };
    const GetApiData = await fetch(path, options)
        .then((response) => {
            // if (response.status == 200) {
            //     return response.json();
            // }
            return response.json()
        })
        .catch((err) => {
            return err.response;
        });
    return GetApiData;
};