const LIVE = Number(process.env.REACT_APP_LIVE);

const api_base_url = {
    1: process.env.REACT_APP_LIVE_BASE_URL
}


export const ApiBaseUrl = api_base_url[LIVE];


export const API_PATH = {
    Getboard: ApiBaseUrl + 'api/v1/board',
    Getmedium: ApiBaseUrl + 'api/v1/medium',
    Getstandard: ApiBaseUrl + 'api/v1/standard',
    Getsubject: ApiBaseUrl + 'api/v1/subject',
    Getsemester: ApiBaseUrl + 'api/v1/semester',
    Getchapter: ApiBaseUrl + 'api/v1/chapter',
    Gettopics: ApiBaseUrl + 'api/v1/topic',
    Getquestions: ApiBaseUrl + 'api/v1/question',
    Getproxyurl : ApiBaseUrl + 'api/v1/proxy?url=',
    Getvideo: ApiBaseUrl + 'api/v1/video',
    GetAllvariants: ApiBaseUrl + 'api/v1/allVariants',

}

const colors = ['#0DA659', '#7196FF', '#FD9A22', '#18AEFF', '#FF7560', '#4284F3', '#9F62DD', '#E37718', '#7B61FF', '#f19065', '#556ee6', '#c34468', '#E25F25', '#EC8810', '#0DA659', '#0E9DEA', '#C34568', '#1262E9', '#00ADB8', '#EFB60A', '#9344E2', '#E76693'];

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

let shuffledColors = shuffle([...colors]);
let colorIndex = 0;

export function generateRandomColor(alpha) {
    
    if (colorIndex >= shuffledColors.length) {
        shuffledColors = shuffle([...colors]);
        colorIndex = 0;
      }
      return shuffledColors[colorIndex++];
}

export function queryStringToJSON(queryString) {
    if (queryString.charAt(0) === '?') {
        queryString = queryString.substring(1);
    }

    var params = queryString.split('&');
    var result = {};

    for (var i = 0; i < params.length; i++) {
        var param = params[i].split('=');
        if (param.length === 2) {
            var key = decodeURIComponent(param[0]);
            var value = decodeURIComponent(param[1]);
            result[key] = value;
        }
    }

    return result;
}

// Function to get unique objects
