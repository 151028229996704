import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { queryStringToJSON } from '../const';
import Pdfviewer from '../Componets/Pdfviewer';

const Pdfviwercomponet = ({ url, page, isLoaded, totalpage }) => {
    return useMemo(() => (
        <Pdfviewer pdfUrl={url} pdfpage={page} isLoaded={isLoaded} totalpage={totalpage} />
    ), [url, page, isLoaded, totalpage]);
}

function Pdfview(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [url, set_url] = useState('');
    const [page, set_page] = useState(null);

    //pdf-state
    const [loaded, set_loaded] = useState(false);
    const [numPages, setNumPages] = useState(null);

    const pdfContainerRef = useRef(null);

    useEffect(() => {
        const data = queryStringToJSON(location.search);
        set_url(data.url);
        set_page(Number(data.page) !== 0 ? Number(data.page) : 1);
    }, [location]);

    const handleSwipe = (event) => {
        const swipeThreshold = 50; // Adjust as needed
        const startX = event.touches[0].clientX;
        let deltaX = 0;

        const onTouchMove = (moveEvent) => {
            deltaX = moveEvent.touches[0].clientX - startX;
        };

        const onTouchEnd = () => {
            const pdfContainer = pdfContainerRef.current;
            if (deltaX > swipeThreshold) {
                // Swipe right
                if (page > 1) {
                    set_page(page - 1);
                    pdfContainer.classList.add('prev');
                    setTimeout(() => pdfContainer.classList.remove('prev'), 1000); // Remove class after animation
                }
            } else if (deltaX < -swipeThreshold) {
                // Swipe left
                if (page < numPages) {
                    set_page(page + 1);
                    pdfContainer.classList.add('next');
                    setTimeout(() => pdfContainer.classList.remove('next'), 1000); // Remove class after animation
                }
            }

            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('touchend', onTouchEnd);
        };

        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchend', onTouchEnd);
    };

    const handlePageChange = (newPage, direction) => {
        const pdfContainer = pdfContainerRef.current;
        set_page(newPage);
        pdfContainer.classList.add(direction);
        setTimeout(() => pdfContainer.classList.remove(direction), 1000); // Remove class after animation
    };

    return (
        <div className='container-md h-100'>
            <section className='row py-3 sticky-top z-1 top-0 bg-white shadow-sm'>
                <div className='col-12 d-flex align-items-center'>
                    <div className='back-arrow' onClick={() => navigate('/subject' + location.state.current, { state: { data: location.state.prev } })}>
                        <span className='cursor-pointer'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 8L1 8" stroke="#363D4E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 15L1 8L8 1" stroke="#363D4E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <div className="common-title mx-auto">
                        <h3 className="mb-0">E-book</h3>
                    </div>
                </div>
            </section>
            
            <section className="row scroll-quiz">
                <div className='col-md-12 pdf-view-sec' ref={pdfContainerRef} onTouchStart={handleSwipe}>
                    {url !== '' &&
                        <Pdfviwercomponet url={url} page={page} isLoaded={set_loaded} totalpage={setNumPages} />
                    }
                </div>
            </section>
            {loaded && (
                <section className='row py-3 sticky-bottom z-1 top-0 bg-white shadow-sm border-top pagination'>
                    <div className='col-md-12'>
                        <nav className=''>
                            <ul className="pager d-flex align-items-center list-none mb-0 justify-content-between">
                                <li className="previous">
                                    <button
                                        disabled={page === 1}
                                        className='btn p-0 border-0'
                                        onClick={() => handlePageChange((page >= 2) ? (page - 1) : page, 'prev')}
                                    >
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d_209_2937)">
                                                <rect x="2" y="1" width="36" height="36" rx="8" fill="white" />
                                                <rect x="2.5" y="1.5" width="35" height="35" rx="7.5" stroke="#D0D5DD" />
                                                <path d="M25.8334 19H14.1667M14.1667 19L20 24.8333M14.1667 19L20 13.1666" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_209_2937" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="1" />
                                                    <feGaussianBlur stdDeviation="1" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_209_2937" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_209_2937" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </button>
                                </li>
                                <li>{`${page} / ${numPages}`}</li>
                                <li className="next">
                                    <button
                                        disabled={page === numPages}
                                        onClick={() => handlePageChange((page <= numPages) ? (page + 1) : page, 'next')}
                                        className='btn p-0 border-0'
                                    >
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d_209_2939)">
                                                <rect x="2" y="1" width="36" height="36" rx="8" fill="white" />
                                                <rect x="2.5" y="1.5" width="35" height="35" rx="7.5" stroke="#D0D5DD" />
                                                <path d="M14.1666 19H25.8333M25.8333 19L20 13.1666M25.8333 19L20 24.8333" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_209_2939" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="1" />
                                                    <feGaussianBlur stdDeviation="1" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_209_2939" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_209_2939" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </section>
            )}
        </div>
    );
}

export default Pdfview;
