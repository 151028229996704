import React, { useEffect, useState } from 'react';
import Pdf from "@mikecousins/react-pdf";
import { API_PATH } from '../const';
import { ProgressBar } from 'react-bootstrap';

const PDFViewer = ({ pdfUrl, pdfpage, isLoaded, totalpage }) => {


    const [page, setPage] = useState(1);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [pdfBlobUrl, setPdfBlobUrl] = useState(null); // State to store blob URL

    useEffect(()=>{
        setPage(pdfpage)
    },[pdfpage])

    function onDocumentLoadSuccess(page) {
        isLoaded(true);
        totalpage(page._transport._numPages)
    }

    function onLoadError(error) {
        console.error('Error while loading document!', error);
    }

    useEffect(() => {
        // Fetch PDF and create blob URL
        fetchPdfAndCreateBlobUrl(pdfUrl);
    }, []);

    async function fetchPdfAndCreateBlobUrl(url) {
        try {
            const reqHeaders = new Headers();
            reqHeaders.set("Cache-Control", "max-age=604800");

            const options = {
                headers: reqHeaders,
              };

            const response = await fetch(url,options);
            
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const totalSize = parseInt(response.headers.get('content-length'), 10);
            let loaded = 0;
            const reader = response.body.getReader();
            let chunks = [];
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                chunks.push(value);
                loaded += value.length;
                setLoadingProgress(loaded / totalSize);
            }

            const blob = new Blob(chunks, { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);
            setPdfBlobUrl(pdfUrl);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    }
    return (
        <>
            {pdfBlobUrl ? (
                <Pdf file={pdfBlobUrl} page={page} scale={1} onPageRenderSuccess={onDocumentLoadSuccess} onPageLoadFail={onLoadError} rotate={360}>
                    {({canvas }) => (
                        <>
                            <div className='w-100 pdf-render h-100 position-relative'>
                                {canvas}
                                <div className="pageTurn"></div>
                            </div>
                        </>
                    )}
                </Pdf>
            )
                :
                (
                    <ProgressBar animated now={loadingProgress * 100} label={`Loading ${Math.round(loadingProgress * 100)}%`} />
                )
            }


        </>
    );
};

export default PDFViewer;













// import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
// // import 'react-pdf/dist/Page/AnnotationLayer.css';
// import { API_PATH } from '../const';
// import { ProgressBar } from 'react-bootstrap';
// import HTMLFlipBook from 'react-pageflip';

// const PDFViewer = ({ pdfUrl, pdfpage, isLoaded, totalpage }) => {
//     const [numPages, setNumPages] = useState(null);
//     const [loadingProgress, setLoadingProgress] = useState(0);
//     const [pdfBlobUrl, setPdfBlobUrl] = useState(null); // State to store blob URL

//     useEffect(() => {
//         fetchPdfAndCreateBlobUrl(pdfUrl);
//     }, [pdfUrl]);

//     async function fetchPdfAndCreateBlobUrl(url) {
//         try {
//             const reqHeaders = new Headers();
//             reqHeaders.set("Cache-Control", "max-age=604800");

//             const options = {
//                 headers: reqHeaders,
//             };

//             const response = await fetch(url, options);
//             if (!response.ok) {
//                 throw new Error(`HTTP error! Status: ${response.status}`);
//             }
//             const totalSize = parseInt(response.headers.get('content-length'), 10);
//             let loaded = 0;
//             const reader = response.body.getReader();
//             let chunks = [];
//             while (true) {
//                 const { done, value } = await reader.read();
//                 if (done) break;
//                 chunks.push(value);
//                 loaded += value.length;
//                 setLoadingProgress(loaded / totalSize);
//             }

//             const blob = new Blob(chunks, { type: 'application/pdf' });
//             const pdfUrl = URL.createObjectURL(blob);
//             setPdfBlobUrl(pdfUrl);
//         } catch (error) {
//             console.error('Error fetching PDF:', error);
//         }
//     }

//     function onDocumentLoadSuccess({ numPages }) {
//         setNumPages(numPages);
//         isLoaded(true);
//         totalpage(numPages);
//     }

//     function onLoadError(error) {
//         console.error('Error while loading document!', error);
//     }

//     return (
//         <>
//             {pdfBlobUrl ? (
//                 <Document
//                     file={pdfBlobUrl}
//                     onLoadSuccess={onDocumentLoadSuccess}
//                     onLoadError={onLoadError}
//                     loading={<ProgressBar animated now={loadingProgress * 100} label={`Loading ${Math.round(loadingProgress * 100)}%`} />}
//                 >
//                     <HTMLFlipBook width={575} height={350}>
//                         {Array.from(new Array(numPages), (el, index) => (
//                             <div key={`page_${index + 1}`}>
//                                 <Page pageNumber={index + 1} />
//                             </div>
//                         ))}
//                     </HTMLFlipBook>
//                 </Document>
//             ) : (
//                 <ProgressBar animated now={loadingProgress * 100} label={`Loading ${Math.round(loadingProgress * 100)}%`} />
//             )}
//         </>
//     );
// };

// export default PDFViewer;
