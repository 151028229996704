import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Drawer } from 'vaul';
import { FetchPostApi } from '../Apiservice';
import { API_PATH, generateRandomColor, queryStringToJSON } from '../const';
import Loader from '../Componets/Loader';


function Subject(props) {
    const location = useLocation()
    const navigate = useNavigate();
    const [semester_list, set_semester_list] = useState([])
    const [selectsem, set_selectsem] = useState('')
    const [param, setparam] = useState({})
    const [chapter, setchapter] = useState([])
    const [color_list, setcolor_list] = useState([])

    const [topic, settopic] = useState([])
    const [que_drawer, setque_drawer] = useState(false)

    const [selecttopicdata, setselecttopicdata] = useState({})
    const [selectchapid, setselectchapid] = useState('')
    const [offset, setoffset] = useState('')
    const [back_state, setback_state] = useState('')
    const [text_book_url, settext_book_url] = useState('')
    const [loader, setloader] = useState(false)
    const [progress, setProgress] = useState(0);



    useEffect(() => {
        if (back_state == '') setback_state(location?.state?.data ?? '')
        const json_data = queryStringToJSON(location.search)
        setparam(json_data)
        if (json_data.board_id && json_data.medium_id && json_data.standard_id && json_data.subject_id) {
           if(semester_list.length == 0) Getsemester(json_data)
            if (json_data.chap_id) {
                Gettopiclist(json_data.chap_id)
            }
        }
    }, [location])

    const Getsemester = async (data) => {
        const temp = { ...data }
        delete temp.chap_id
        const semester = await FetchPostApi(API_PATH.Getsemester, {
            "page": 1,
            "limit": 100,
            "whereArray": temp
        })
        if (semester.success) {
            const data = semester.data.data
            set_semester_list(data)
            set_selectsem(data[0]?.id)
            settext_book_url(data[0]?.textbook_url ?? '')

        }
    }


    useEffect(() => {
        async function fetchchapter() {
            if (selectsem != '') {
                const temp = { ...param }
                delete temp.chap_id
                const chapter = await FetchPostApi(API_PATH.Getchapter, {
                    "page": 1,
                    "limit": 100,
                    "whereArray": { ...temp, semester_id: selectsem }
                })
                if (chapter.success) {
                    const data = chapter.data.data
                    setchapter(data);
                    setoffset(chapter.data.paginateOption.offset)
                    if (data.length > 0) {
                        const colors = data.map((_, i) => { return generateRandomColor() })
                        setcolor_list(colors)
                    }
                }
            }
        }
        fetchchapter()

    }, [selectsem])

    const Gettopics = async (id) => {
        setselectchapid(id)
        const regex = /(&|^)chap_id=(\d+)(?=.*?(&|$))/g;
        navigate('/subject' + location.search.replace(regex, '') + '&' + `chap_id=${id}`)
        // const topic = await FetchPostApi(API_PATH.Gettopics, {
        //     "page": 1,
        //     "limit": 100,
        //     "whereArray": { chapter_id: id }
        // })
        // if (topic.success) {
        //     const data = topic.data.data
        //     settopic(data);
        //     setque_drawer(true)
        // }
    }
    const Gettopiclist = async (id) => {
        setselectchapid(id)
        const topic = await FetchPostApi(API_PATH.Gettopics, {
            "page": 1,
            "limit": 100,
            "whereArray": { chapter_id: id }
        })
        if (topic.success) {
            const data = topic.data.data
            settopic(data);
            setque_drawer(true)
        }
    }

    function createMarkup(data) {
        return { __html: data };
    }

    const downloadFile = async (url) => {
        const all_sem = structuredClone(semester_list)
        const fileName = all_sem.find((e)=>e.id == selectsem).textbook_name + '.pdf'

        try {
            const response = await fetch(API_PATH.Getproxyurl + url);
            if (!response.ok) throw new Error('Network response was not ok');

            const contentLength = response.headers.get('content-length');
            if (!contentLength) throw new Error('Content-Length header is missing');

            const total = parseInt(contentLength, 10);
            let loaded = 0;
            setloader(true)

            const reader = response.body.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    function push() {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            loaded += value.length;
                            const percent = (loaded / total) * 100;
                            setProgress(percent);

                            controller.enqueue(value);
                            push();
                        }).catch(err => {
                            console.error('Read error:', err);
                            controller.error(err);
                        });
                    }

                    push();
                }
            });

            const newResponse = new Response(stream);
            const blob = await newResponse.blob();
            const downloadUrl = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download =fileName || url.split('/').pop();  // Use the file name from the URL
            document.body.appendChild(a);
            a.click();
            a.remove();
            setloader(false)
        } catch (error) {
            console.error('Download error:', error);
            setloader(false)
        }
    };


    return (
        <>
        {loader && <Loader progress={progress} />}
        <div className='container-md' >
            <section className='row py-3 sticky-top z-1 top-0 bg-white shadow-sm'>
                <div className='col-12 d-flex align-items-center'>
                    <div className='back-arrow'>
                        <span className='cursor-pointer'
                            onClick={() => navigate('/' + back_state)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 8L1 8" stroke="#363D4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 15L1 8L8 1" stroke="#363D4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <div className="common-title mx-auto">
                        <h3 className="mb-0">Chapter</h3>
                    </div>
                    {text_book_url && text_book_url != '' &&
                        <div className='cursor-pointer' onClick={() => downloadFile(text_book_url)}>
                            <svg width="30" height="30" x="0" y="0" viewBox="0 0 24 24"><g><linearGradient id="a" x1="16.68" x2="6.926" y1="21.705" y2="1.96" gradientUnits="userSpaceOnUse"><stop stop-opacity="1" stop-color="#4a65ff" offset="0.003"></stop><stop stop-opacity="1" stop-color="#495eca" offset="1"></stop></linearGradient><circle cx="11.886" cy="12" r="11.997" fill="url(#a)" opacity="1" data-original="url(#a)"></circle><g fill="#fff8f8"><path d="M11.532 14.957c.035.028.075.042.113.063.032.018.06.039.094.052.09.035.183.056.278.056s.188-.021.278-.056c.034-.013.062-.035.094-.052.038-.022.08-.036.115-.064l3.858-3.123a.774.774 0 1 0-.974-1.204l-2.597 2.102V5.774a.774.774 0 1 0-1.549 0v6.964l-2.626-2.11a.774.774 0 1 0-.97 1.207z" fill="#fff8f8" opacity="1" data-original="#fff8f8"></path><path d="M18.979 13.554a.774.774 0 0 0-.774.775v1.657c0 .808-.675 1.465-1.504 1.465H7.3c-.829 0-1.504-.657-1.504-1.465v-1.657a.774.774 0 1 0-1.548 0v1.657c0 1.662 1.37 3.014 3.052 3.014H16.7c1.683 0 3.052-1.352 3.052-3.014v-1.657a.774.774 0 0 0-.774-.775z" fill="#fff8f8" opacity="1" data-original="#fff8f8"></path></g></g>
                            </svg>
                        </div>
                    }
                </div>
            </section>
            {semester_list.length > 0 &&
                <>
                    <section className="row mt-2">
                        <div className="col-md-12">
                            <div className="cust-tab-sem">
                                <ul>
                                    {semester_list.map((item, i) => {
                                        return (
                                            <li key={i} >
                                                <input
                                                    type='radio'
                                                    id={item.id}
                                                    defaultChecked={item.id == selectsem}
                                                    onChange={() => {
                                                        set_selectsem(item.id)
                                                        settext_book_url(item.textbook_url ?? '')
                                                    }}

                                                    value={item.id}
                                                    name='semester'
                                                    className='d-none'
                                                />
                                                <label htmlFor={item.id} className='cursor-pointer'>
                                                    {item.name}
                                                </label>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className='row mt-3 cust-subject'>
                        {chapter.length > 0 && chapter.map((item, i) => {
                            return (
                                <div className='col-12 cust-m-6' key={i}>
                                    <div className='subject-box chap-box cursor-pointer relative box-icon-new' style={{ backgroundColor: color_list[i] }}>
                                        <div className='icon-box text-end mb-1 position-absolute right-0' onClick={() => Gettopics(item.id)}>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="28" height="28" rx="8" fill="white" />
                                                <path d="M12.5 17L15.5 14L12.5 11" stroke="#363D4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <bdi className="d-block">Chap {offset + i}</bdi>
                                        <span>{item.name}</span>
                                        <div className='d-flex mt-3 gap-2'>
                                            {text_book_url && text_book_url != '' &&
                                                <Link to={{
                                                    pathname: '/pdfview',
                                                    search: `?url=${text_book_url}&page=${item.textbook_page.split('-')[0]}`

                                                }}
                                                    state={{ current: location.search, prev: location?.state?.data }}
                                                    className='btn btn-comn-1'>

                                                    <svg className='me-2' width="20" height="20" x="0" y="0" viewBox="0 0 487.141 487.141"><g><path d="M487.085 411.037V117.064c.074-.709.074-1.424 0-2.133.046-.568.046-1.139 0-1.707l-1.067-1.707-1.28-1.707h-1.493l-1.92-1.28a374.7 374.7 0 0 0-35.84-10.667v-49.92c0-5.891-4.776-10.667-10.667-10.667-75.559.77-146.326 37.142-190.933 98.133-44.179-61.043-114.73-97.467-190.08-98.133-5.891 0-10.667 4.776-10.667 10.667V96.37a358.85 358.85 0 0 0-36.053 10.88l-2.133 1.28H3.458l-1.28 1.707-1.067 1.707a10.809 10.809 0 0 0 0 1.707 10.465 10.465 0 0 0-1.067 2.347V411.25c-.06.639-.06 1.281 0 1.92.173.514.387 1.014.64 1.493.338.76.768 1.477 1.28 2.133v1.28l1.707 1.28 1.707 1.067h7.68a260.268 260.268 0 0 1 224.64 28.373l1.92 1.067h8.32a262.186 262.186 0 0 1 223.787-27.52h7.68l1.92-1.28 1.493-1.067v-1.493a10.454 10.454 0 0 0 4.267-3.84 9.93 9.93 0 0 0 0-1.707c.06-.638.06-1.28 0-1.919zm-465.92-14.293V124.957c6.684-2.276 13.796-4.409 21.333-6.4V334.45c-.011 5.891 4.756 10.675 10.647 10.686.22 0 .44-.006.659-.019l.213.213a208.427 208.427 0 0 1 147.2 60.8 286.503 286.503 0 0 0-180.052-9.386zm212.053 11.733a234.666 234.666 0 0 0-168.533-85.333V58.824a220.374 220.374 0 0 1 168.533 97.707v251.946zm21.547.853v-252.8a223.362 223.362 0 0 1 168.747-97.707h.213v265.173a236.166 236.166 0 0 0-168.96 85.334zm211.627-12.586a287.78 287.78 0 0 0-179.84 9.387 209.922 209.922 0 0 1 147.627-61.013c5.891 0 10.667-4.776 10.667-10.667V118.557a333.726 333.726 0 0 1 21.547 6.4v271.787z" fill="#363D4E" opacity="1" data-original="#363D4E" className=""></path></g></svg>
                                                    Book
                                                </Link>
                                            }
                                            {item.video == 1 &&

                                                <Link to={{
                                                    pathname: '/videos',
                                                    search: `${location.search}&chapter_id=${item.id}`

                                                }}
                                                    state={{ current: location.search, prev: location?.state?.data }}
                                                    className='btn btn-comn-1'>
                                                    <svg className='me-2' width="20" height="20" x="0" y="0" viewBox="0 0 512 512"><g><path d="m338.95 243.28-120-75A15.002 15.002 0 0 0 195.999 181v150a15 15 0 0 0 22.95 12.72l120-75a15 15 0 0 0 .001-25.44zM226 303.936v-95.873L302.698 256z" fill="#363d4e" opacity="1"></path><path d="M437 61H75C33.645 61 0 94.645 0 136v240c0 41.355 33.645 75 75 75h362c41.355 0 75-33.645 75-75V136c0-41.355-33.645-75-75-75zm45 315c0 24.813-20.187 45-45 45H75c-24.813 0-45-20.187-45-45V136c0-24.813 20.187-45 45-45h362c24.813 0 45 20.187 45 45z" fill="#363d4e" opacity="1"></path></g></svg>
                                                    <>Video</>
                                                </Link>
                                            }

                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </section>
                </>
            }


            <Drawer.Root
                shouldScaleBackground={true}
                open={que_drawer}
                dismissible={true}
                fixed={true}
                setActiveSnapPoint={500}
                onClose={() => setque_drawer(false)}
            >
                <Drawer.Portal>
                    <div onClick={() => setque_drawer(false)}>
                        <Drawer.Overlay className='cust-overlay-drawer' />
                    </div>
                    <Drawer.Content>
                        <div className='drawer-inner pt-2'>
                            <div className='row'>
                                <div className='col-md-12 d-flex justify-content-center'>
                                    <div className='border-top-notch'>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    {(() => {
                                        const filter = chapter.filter((e) => e.id == selectchapid)
                                        if (filter.length > 0) {
                                            const findindex = chapter.findIndex((e) => e.name == filter[0].name)
                                            return (
                                                <div className='d-flex'>
                                                    <h3>
                                                        <p className='mb-1'>Chap {findindex + offset}</p>
                                                        {filter[0].name}
                                                    </h3>
                                                    <div className="ms-auto">
                                                        <bdi className='sem-div'>{filter[0].semester_name}</bdi>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })()}
                                </div>
                                <div className='col-md-12 scrollable-drawer'>
                                    <ul className='cust-select-radio mt-3'>
                                        {topic.length > 0 && topic.map((item, i) => {
                                            return (
                                                <li>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="boardlist"
                                                        id={'medium' + item.id}
                                                        value={item.id}
                                                        onChange={(e) => {
                                                            navigate('/quiz', {
                                                                state: {
                                                                    data: {
                                                                        "chapter_id": selectchapid,
                                                                        "topic_id": item.id,
                                                                    },
                                                                    'title_top': item.sub_topic,
                                                                    current: location.search,
                                                                    back_state
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    <label className="form-check-label d-flex align-items-center" htmlFor={'medium' + item.id}>
                                                        <span><span dangerouslySetInnerHTML={createMarkup(item.sub_topic)}></span></span>

                                                        <svg className="ms-auto" width="10" height="10" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.5 7.5L4.5 4.5L1.5 1.5" stroke="#040C22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </label>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>
        </div>
        </>
    );
}

export default Subject;