import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Privacy from './Pages/Privacy';
import Quiz from './Pages/Quiz';
import Subject from './Pages/Subject';
import Pdfview from './Pages/Pdfview';
import Videos from './Pages/Videos';

export default function Mainroutes(props) {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Home />} />

                <Route exact path='/home' element={<Home />} />
                <Route exact path='/subject' element={<Subject />} />
                <Route exact path='/quiz' element={<Quiz />} />
                <Route exact path='/pdfview' element={<Pdfview />} />
                <Route exact path='/videos' element={<Videos />} />


                <Route exact path='/privacy' element={<Privacy />} />
            </Routes>
        </BrowserRouter>
    );
}
